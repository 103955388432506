<div class="wrapper">
  <div class="header">
    <div class="page-title">Valide a Autenticidade do</div>
    <span class="page-title" style="color: #40e47c; font-weight: 500; line-height: normal;">Documento</span>
    <div class="page-subtitle">Verifique a conformidade e valide um documento</div>
  </div>
  <mat-card appearance="outlined" class="card-form">
    <img src="../logo-sema.png">

    <form [formGroup]="aFormGroup" class="form-group">
      <div class="input-group">
        <div class="input-title">Código de validação</div>
        <mat-form-field appearance="outline" style="width: 100%; height: 56px;">
          <input matInput placeholder="Código" formControlName="codigo">
        </mat-form-field>
      </div>

      <ngx-recaptcha2 style="width: 100%;" *ngIf="siteKey" [siteKey]="siteKey" formControlName="recaptcha"></ngx-recaptcha2>

      <button mat-raised-button class="send-button" type="button" (click)="submitForm()"
        [disabled]="!aFormGroup.valid">Validar documento</button>
    </form>
  </mat-card>
  <div class="footer">
    <span style="font-size: 14px; font-weight: 300;">Desenvolvido por <span style="font-weight: 500;">SCCON
        Geospatial</span> © {{currentYear}} Todos os direitos reservados.</span>
  </div>
</div>