<div class="header">
    <button (click)="closePreview()" class="close-btn">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
    </button>
</div>
<div *ngIf="pdfLink" style="height: 100%;">
    <iframe [src]="pdfLink" width="100%" height="100%"></iframe>
</div>