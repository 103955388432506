import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_API } from '../api.const';

export interface ApiResponse {
  requestId: string;
  data: [{
    url: string;
  }] 
  errors: any[];
}

@Injectable({
  providedIn: 'root'
})
export class ReportValidatorService {
  constructor(private http: HttpClient) { }

  sendFormData(code: string, captcha: string): Observable<ApiResponse> {
    const url = `${BASE_API}/notifications/validate?code=${code}`;
    return this.http.get<ApiResponse>(url);
  }
}
