import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportValidatorService, ApiResponse } from '../service/report-validator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { first } from "rxjs";

@Component({
  selector: 'app-central-card',
  templateUrl: './central-card.component.html',
  styleUrls: ['./central-card.component.scss']
})
export class CentralCardComponent implements OnInit {
  aFormGroup: FormGroup;
  siteKey: string = environment.recaptchaSiteKey;
  pdfLink: string | null = null;
  currentYear: number = 0

  constructor(
    private reportValidatorService: ReportValidatorService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {
    this.aFormGroup = this.formBuilder.group({
      codigo: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
    this.route.queryParams.subscribe(params => {
      if (params['code']) {
        this.aFormGroup.patchValue({
          codigo: params['code']
        });
      }
    });
  }

  submitForm() {
    if (this.aFormGroup.valid) {
      const codigo = this.aFormGroup.get('codigo')?.value;
      const recaptcha = this.aFormGroup.get('recaptcha')?.value;

      if (codigo && recaptcha) {
        this.reportValidatorService.sendFormData(codigo, recaptcha)
          .pipe(first())
          .subscribe(
            (response: ApiResponse) => {
              if (response && response.data && response.data[0].url) {
                this.pdfLink = response.data[0].url;
                const previewUrl = '/#/preview?link=' + this.pdfLink;
                const fullUrl = `${window.location.origin}${previewUrl}`;
                window.open(fullUrl, '_blank');
              } else {
                console.log('Link não encontrado na resposta.');
              }
            },
            (error) => {
              this.snackBar.open('Erro ao realizar a validação', '', {
                duration: 5000,
                panelClass: 'snackbar-error',
                horizontalPosition: 'right',
                verticalPosition: 'top'
              });
            }
          );
      } else {
        console.log('Code ou Captcha estão ausentes.');
      }
    } else {
      this.aFormGroup.markAllAsTouched();
    }
  }
}
