import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CentralCardComponent } from './central-card/central-card.component';
import { PreviewPageComponent } from './preview-page/preview-page.component';

const routes: Routes = [
  { path: '', component: CentralCardComponent },
  { path: 'preview', component: PreviewPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
